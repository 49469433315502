//  <--COMPONENTS--> //
import { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router';
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
import { DatePicker, ConfigProvider, Tooltip, Checkbox, Popover } from 'antd';
import { LeftOutlined, RightOutlined, MoreOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import RecolectionCard from '../../../components/dataCenterComponents/recolectioncard/recolectioncard';
import BZeroIndexCard from '../../../components/dataCenterComponents/bzeroIndexCard/bzeroindexcard';
//  <--REDUX--> //
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { PropertyStoreInterface } from '../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../store/slices/property-unit/property-unit.interface';
import { MaterialStoreInterface } from '../../../store/slices/material/material.interface';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { setMaterialData } from '../../../store/slices/material';
import useWindowDimensions from '../../../config/hooks/useWindowDimentions';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
import './evolution.scss';
import { useCallApi } from '../../../config/hooks/useCallApi';
import {
    FormatLabels,
    GetBzeroIndex
} from '../../../config/utils/IdbFormatData';
import { initValues } from '../data';
import { StackedBarChart } from '../../../components/charts';
import { lang } from '../../langs';
import { NormalizeName, Zfill } from '../../../config/utils/Utilities';
import {
    ApiDownloadChartsData,
    ApiPropertyDataByPropertyGestor,
    ApiPropertyDataByPropertyUnit
} from '../../../config/service';

const Stacked1 = StackedBarChart;
Stacked1.Config.plugins.legend.display = false;
Stacked1.Config.plugins.legend.position = 'top';
Stacked1.Config.plugins.title.text = '';

const { RangePicker } = DatePicker;

interface MaterialItem {
    name: string;
    selected: boolean;
    show: boolean;
}

const Evolution = () => {
    const { preferences, userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageDataCenter: {
                pageEvolution: {
                    title,
                    downloadDataButton,
                    downloadChartButton,
                    dateRangeTitle,
                    viewDetailsText
                },
                months
            }
        }
    } = lang;
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { propertyData, selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );
    const { materialData }: MaterialStoreInterface = useAppSelector(
        state => state.material
    );
    const [labels, setLabels] = useState<string[]>([]);
    const [dataApiledChart, setDataApiledChart] = useState<any>(initValues);
    const [dateFilterRange, setDateFilterRange] = useState<string[]>([]);
    const [labelList, setLabelList] = useState<string[]>([]);
    const [formattedLabels, setFormattedLabels] = useState<string[]>([]);
    const [defaultStartDate, setDefaultStartDate] = useState<any>();
    const [defaultEndDate, setDefaultEndDate] = useState<any>();
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [expandOpen, setExpandOpen] = useState<boolean>(false);
    /* const [materials, setMaterials] = useState<MaterialsAvailableInterface[]>(
        []
    ); */
    const [removedDatasets, setRemovedDatasets] = useState<any[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(true);
    const [recolectionData, setRecolectionData] = useState<any[]>([]);
    const [bzeroindex, setBzeroindex] = useState<number>(0);
    const navigate = useNavigate();
    const ref = useRef<any>(null);
    const dispatch = useAppDispatch();

    const DownloadChart = useCallback(() => {
        if (ref.current) {
            const link = document.createElement('a');
            link.download = 'chart.jpg';
            link.href = ref.current.toBase64Image('image/jpg', 1);
            link.click();
        }
    }, []);

    const adjustToEndOfMonth = date => {
        const year = date.year();
        const month = parseInt(date.month()) + 1; // month() devuelve 0-11, por lo que sumamos 1
        const lastDayOfMonth = dayjs(`${year}-${month}`).daysInMonth();
        return dayjs(
            `${year}-${String(month).padStart(2, '0')}-${lastDayOfMonth}`
        ).format('YYYY-MM-DD');
    };

    const DownloadData = async () => {
        const ids =
            selected.length + selectedUnits.length > 0
                ? selected.map(item => item.id)
                : propertyData.map(item => item.id);
        const idsUnits = selectedUnits.map(item => {
            return item.id;
        });
        const idsMaterials = materialData
            .filter(item => item.selected)
            .map(item => item.name);

        let gestor: string | null = null;
        for (const role of userData.roles) {
            if (role.gestorData) {
                gestor = role.gestorData.id;
                break;
            }
        }
        const ini =
            dateFilterRange && dateFilterRange.length > 0
                ? `${dateFilterRange[0].substring(
                      0,
                      4
                  )}-${dateFilterRange[0].substring(4, 6)}-01`
                : defaultStartDate.format('YYYY-MM-DD');
        const fin =
            dateFilterRange && dateFilterRange.length > 1
                ? adjustToEndOfMonth(
                      dayjs(
                          `${dateFilterRange[1].substring(
                              0,
                              4
                          )}-${dateFilterRange[1].substring(4, 6)}-01`
                      )
                  )
                : adjustToEndOfMonth(defaultEndDate);
        const data = {
            filters: {
                propertyIds: ids,
                units: idsUnits,
                gestors: [gestor],
                dateRange: {
                    ini,
                    fin
                },
                materials: idsMaterials
            }
        };
        const response = await GetData(
            ApiDownloadChartsData,
            HTTP_METHODS.POST,
            data
        );
        const {
            data: { filepathdownload }
        } = response;
        window.open(filepathdownload, '_blank');
    };

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        const totals: Record<number, number> = {}; // Aquí se almacenarán los totales

        dataApiledChart.datasets.forEach(item => {
            if (item.label !== 'Índice BZero') {
                item.data.forEach((value, index) => {
                    // Convierte el valor a un número y súmalo al total correspondiente
                    totals[index] = (totals[index] || 0) + parseFloat(value);
                });
            }
        });
        // Ahora, calcula el máximo de los totales
        const maxTotal = Math.max(...Object.values(totals));
        if (maxTotal > 1000) {
            Stacked1.AddTicks.title.text = 'Suma acumulada (mil [kg])';
        } else {
            Stacked1.AddTicks.title.text = 'Suma acumulada ([kg])';
        }
        if (!isMobile) {
            Stacked1.Config.plugins.legend.display = false;
        }
        Stacked1.Config.plugins.legend.position = 'bottom';
        Stacked1.Config.plugins.legend.align = 'start'; // Alinea la leyenda a la izquierda
        Stacked1.Config.plugins.title.text = '';
        Stacked1.Config.scales.x.display = true;
        Stacked1.Config.scales.y = {
            ...Stacked1.Config.scales.y,
            ...Stacked1.AddTicks
        };
    }, [dataApiledChart]);

    const formatLabelsShow = labelsArray => {
        const formatted = labelsArray.map(label => {
            const month = label.substring(4, 6); // label es 'yyyymm', así que solo extraemos los últimos 2 caracteres
            return `${months[parseInt(month) - 1].slice(0, 1)}`; // Obtén la primera letra del mes
        });
        setFormattedLabels(formatted);
    };

    const ObtainBZeroIndexes = dataFormatedApiled => {
        const dataBasura = dataFormatedApiled.datasets.filter(
            item => item.label === 'Basura'
        );
        const dataFinal = dataFormatedApiled.datasets.filter(
            item => item.label !== 'Índice BZero'
        );
        if (dataBasura.length > 0) {
            const indexesArray = new Array(dataBasura[0].data.length).fill(0);
            dataFinal.forEach(obj => {
                obj.data.forEach((value, index) => {
                    indexesArray[index] =
                        parseFloat(indexesArray[index]) + parseFloat(value);
                });
            });
            const sum = Array.from(
                { length: dataBasura[0].data.length },
                () => 0
            );
            dataBasura.forEach(item => {
                item.data.forEach((value, index) => {
                    sum[index] += parseFloat(value);
                });
            });
            sum.forEach((item, index) => {
                indexesArray[index] =
                    (item / parseFloat(indexesArray[index])) * 100;
            });
            const indexBZero = dataFormatedApiled.datasets.findIndex(
                item => item.label === 'Índice BZero'
            );
            if (indexBZero !== -1) {
                dataFormatedApiled.datasets[indexBZero].data = indexesArray;
            }
        }
        const updatedMaterials = materialData.map(material => {
            // Encontrar el dataset correspondiente al material actual por su id (material.name)
            const dataset = dataFormatedApiled.datasets.find(
                objeto => objeto.id === material.name
            );
            if (dataset) {
                const total = dataset.data
                    .map(Number)
                    .reduce(
                        (a, b) => (isNaN(b) ? a : Number(a) + Number(b)),
                        0
                    );
                return {
                    ...material,
                    show: total > 0
                };
            }
            return {
                ...material,
                show: false
            };
        });
        dispatch(setMaterialData(updatedMaterials));

        const selectedMaterials = updatedMaterials.filter(
            material => material.selected
        );
        const selectedMaterialIds = selectedMaterials.map(
            material => material.name
        );
        // Filtra dataApiledChart según los IDs de los materiales seleccionados
        if (selectedMaterialIds.length > 0) {
            const filteredDatasets = dataFormatedApiled.datasets.filter(
                dataset => selectedMaterialIds.includes(dataset.id)
            );
            const dataFormatedApiledFiltered = {
                labels: dataFormatedApiled.labels,
                datasets: filteredDatasets
            };
            setDataApiledChart(dataFormatedApiledFiltered);
            const removedDatasets = dataFormatedApiled.datasets.filter(
                dataset => !selectedMaterialIds.includes(dataset.id)
            );
            setRemovedDatasets(removedDatasets);
        }
    };

    const updateAllSelectedData = async () => {
        const uniqueSelected = selected.filter(
            (property, index, self) =>
                index === self.findIndex(p => p.id === property.id)
        );
        const propertyDataList = await Promise.all(
            uniqueSelected.map(async property => {
                const propertyDataStr = localStorage.getItem(
                    `property-${property.id}`
                );
                let propertyData;

                if (propertyDataStr) {
                    propertyData = JSON.parse(propertyDataStr);
                } else {
                    let gestor: string | null = null;
                    for (const role of userData.roles) {
                        if (role.gestorData) {
                            gestor = role.gestorData.id;
                            break;
                        }
                    }
                    propertyData = await GetData(
                        ApiPropertyDataByPropertyGestor,
                        HTTP_METHODS.POST,
                        { property: property.id, gestor }
                    );
                    localStorage.setItem(
                        `property-${property.id}`,
                        JSON.stringify(propertyData)
                    );
                }

                return propertyData;
            })
        );
        const uniqueSelectedUnits = selectedUnits.filter(
            (property, index, self) =>
                index === self.findIndex(p => p.id === property.id)
        );
        // Obtener datos de las unidades seleccionadas
        const propertyUnitDataList = await Promise.all(
            uniqueSelectedUnits.map(async unit => {
                const unitDataStr = localStorage.getItem(
                    `property-unit-${unit.id}`
                );
                let unitData;

                if (unitDataStr) {
                    unitData = JSON.parse(unitDataStr);
                } else {
                    unitData = await GetData(
                        ApiPropertyDataByPropertyUnit(unit.id),
                        HTTP_METHODS.GET
                    );
                    localStorage.setItem(
                        `property-unit-${unit.id}`,
                        JSON.stringify(unitData)
                    );
                }

                return unitData;
            })
        );

        // Combinar datos de propiedades y unidades en una sola lista
        const allDataList = [...propertyDataList, ...propertyUnitDataList];
        // Acumulación de datos para actualizar los estados
        let accumulatedData: any = null;
        // Procesar datos combinados
        allDataList.forEach(data => {
            const evolutionCard = data.data.find(
                item => item.kind === 'evolution-card-historic'
            );
            if (evolutionCard?.data /* && bzeroindexCard?.data */) {
                const newData = JSON.parse(evolutionCard.data);

                if (!accumulatedData) {
                    accumulatedData = newData;
                } else {
                    // Crear un nuevo conjunto que combine ambos datasets, sin perder datos
                    const combinedDatasets: any = [];

                    // Procesar los datasets que están en accumulatedData
                    accumulatedData.datasets.forEach(accumulatedDataset => {
                        const correspondingNewDataset = newData.datasets.find(
                            ds => ds.id === accumulatedDataset.id
                        );

                        if (correspondingNewDataset) {
                            // Si existe en ambos, acumular los datos
                            const updatedDataset: any = {
                                ...accumulatedDataset,
                                data: accumulatedDataset.data.map(
                                    (value, index) => {
                                        const currentValue =
                                            Math.round(parseFloat(value)) || 0;
                                        const newValue =
                                            Math.round(
                                                parseFloat(
                                                    correspondingNewDataset
                                                        .data[index]
                                                )
                                            ) || 0;
                                        return (
                                            currentValue + newValue
                                        ).toString();
                                    }
                                )
                            };
                            combinedDatasets.push(updatedDataset);
                        } else {
                            // Si solo existe en accumulatedData, lo agregamos tal cual
                            combinedDatasets.push(accumulatedDataset);
                        }
                    });

                    // Procesar los datasets que están en newData pero no en accumulatedData
                    newData.datasets.forEach(newDataset => {
                        const existingDataset = accumulatedData.datasets.find(
                            dataset => dataset.id === newDataset.id
                        );

                        if (!existingDataset) {
                            // Si solo existe en newData, lo agregamos tal cual
                            combinedDatasets.push(newDataset);
                        }
                    });

                    // Asignamos el conjunto combinado de datasets
                    accumulatedData.datasets = combinedDatasets;
                }
            }
        });
        setRecolectionData(accumulatedData);
        // Actualizar estados
        if (accumulatedData) {
            const newBreakpoints = labelList
                .map(item => {
                    if (
                        item >= dateFilterRange[0] &&
                        item <= dateFilterRange[1]
                    ) {
                        return item;
                    } else {
                        return '';
                    }
                })
                .filter(el => el !== '');
            const filteredLabels = accumulatedData.labels.filter(date =>
                newBreakpoints.includes(date)
            );
            const firstIndex: number = accumulatedData.labels.findIndex(
                date => date === filteredLabels[0]
            );
            const lastIndex: number = accumulatedData.labels.findIndex(
                date => date === filteredLabels[filteredLabels.length - 1]
            );
            const updatedDatasets = accumulatedData.datasets.map(dataset => ({
                ...dataset,
                data: dataset.data.slice(firstIndex, lastIndex + 1)
            }));
            const updatedParsedEvolutionData = {
                labels: accumulatedData.labels.slice(firstIndex, lastIndex + 1), // Recortar las labels
                datasets: updatedDatasets
            };
            ObtainBZeroIndexes(updatedParsedEvolutionData);
            const bzeroindex = GetBzeroIndex(accumulatedData, 3);
            setBzeroindex(bzeroindex);
        }
    };

    useEffect(() => {
        if (selected.length === 0 && selectedUnits.length === 0) {
            const evolutionCard = localStorage.getItem(
                'evolution-card-historic'
            );
            const parsedEvolutionCard = evolutionCard
                ? JSON.parse(evolutionCard)
                : [];
            const parsedEvolutionData = parsedEvolutionCard.data
                ? JSON.parse(parsedEvolutionCard.data)
                : initValues;
            setRecolectionData(parsedEvolutionData);
            const bzeroindex = GetBzeroIndex(parsedEvolutionData, 3);
            setBzeroindex(bzeroindex);
            const newBreakpoints = labelList
                .map(item => {
                    if (
                        item >= dateFilterRange[0] &&
                        item <= dateFilterRange[1]
                    ) {
                        return item;
                    } else {
                        return '';
                    }
                })
                .filter(el => el !== '');
            const filteredLabels = parsedEvolutionData.labels.filter(date =>
                newBreakpoints.includes(date)
            );
            const firstIndex: number = parsedEvolutionData.labels.findIndex(
                date => date === filteredLabels[0]
            );
            const lastIndex: number = parsedEvolutionData.labels.findIndex(
                date => date === filteredLabels[filteredLabels.length - 1]
            );
            const updatedDatasets = parsedEvolutionData.datasets.map(
                dataset => ({
                    ...dataset,
                    data: dataset.data.slice(firstIndex, lastIndex + 1)
                })
            );
            const updatedParsedEvolutionData = {
                labels: parsedEvolutionData.labels.slice(
                    firstIndex,
                    lastIndex + 1
                ), // Recortar las labels
                datasets: updatedDatasets
            };
            ObtainBZeroIndexes(updatedParsedEvolutionData);

            const updatedMaterials = materialData.map(material => {
                const dataset = updatedParsedEvolutionData.datasets.find(
                    objeto => objeto.id === material.name
                );
                if (dataset) {
                    const total = dataset.data
                        .map(Number)
                        .reduce(
                            (a, b) => (isNaN(b) ? a : Number(a) + Number(b)),
                            0
                        );
                    return {
                        ...material,
                        show: total > 0
                    };
                }
                return {
                    ...material,
                    show: false
                };
            });

            dispatch(setMaterialData(updatedMaterials));
        } else {
            updateAllSelectedData();
        }
    }, [selected, selectedUnits, dateFilterRange]);

    useEffect(() => {
        if (labels.length > 0) {
            const startDate = dayjs(
                `${labels[0].slice(0, 4)}-${labels[0].slice(4, 6)}-01`
            );
            const endDate = dayjs(
                `${labels[labels.length - 1].slice(0, 4)}-${labels[
                    labels.length - 1
                ].slice(4, 6)}-01`
            ).endOf('month');

            // Formatear las fechas como 'yyyymm'
            const formattedStartDate = startDate.format('YYYYMM');
            const formattedEndDate = endDate.format('YYYYMM');

            setDefaultStartDate(startDate);
            setDefaultEndDate(endDate);
            setDateFilterRange([formattedStartDate, formattedEndDate]);
        }
    }, [labels]);

    const ChangeDate = date => {
        const ini = `${date[0].$y}${Zfill(parseInt(date[0].$M) + 1, 2)}`;
        const fin = `${date[1].$y}${Zfill(parseInt(date[1].$M) + 1, 2)}`;
        // eslint-disable-next-line array-callback-return
        setDateFilterRange([ini, fin]);
    };

    const ChangeMaterials = (material: MaterialItem) => {
        if (!material.selected) {
            const addedDataset = removedDatasets.find(
                dataset => dataset.id === material.name
            );
            if (addedDataset) {
                setDataApiledChart(prevData => {
                    const datasets = [...prevData.datasets, addedDataset];
                    const basuraDatasets = datasets.filter(
                        dataset => dataset.label === 'Basura'
                    );
                    const otherDatasets = datasets
                        .filter(dataset => dataset.label !== 'Basura')
                        .sort((a, b) => a.kind.localeCompare(b.kind));
                    const sortedDatasets = [
                        ...basuraDatasets,
                        ...otherDatasets
                    ];
                    return {
                        ...prevData,
                        datasets: sortedDatasets
                    };
                });
                setRemovedDatasets(prevRemovedDatasets =>
                    prevRemovedDatasets.filter(
                        removedDataset => removedDataset.id !== material.name
                    )
                );
            }
        } else {
            const removedDataset = dataApiledChart.datasets.find(
                dataset => dataset.id === material.name
            );
            if (removedDataset) {
                setRemovedDatasets(prevRemovedDatasets => [
                    ...prevRemovedDatasets,
                    removedDataset
                ]);

                setDataApiledChart(prevData => {
                    const datasets = prevData.datasets.filter(
                        dataset => dataset.id !== material.name
                    );
                    return {
                        ...prevData,
                        datasets
                    };
                });
            }
        }
        const updatedMaterials = materialData.map(materialItem =>
            materialItem.name === material.name
                ? { ...materialItem, selected: !materialItem.selected }
                : materialItem
        );
        dispatch(setMaterialData(updatedMaterials));
    };

    const unselectAll = () => {
        const updatedMaterials = materialData.map(material => {
            const removedDataset = dataApiledChart.datasets.find(
                dataset => dataset.id === material.name
            );

            if (selectAll) {
                if (removedDataset) {
                    setRemovedDatasets(prevRemovedDatasets => [
                        ...prevRemovedDatasets,
                        removedDataset
                    ]);

                    setDataApiledChart(prevData => {
                        const datasets = prevData.datasets.filter(
                            dataset => dataset.id !== material.name
                        );
                        return {
                            ...prevData,
                            datasets
                        };
                    });
                }
                return { ...material, selected: false };
            } else {
                const addedDataset = removedDatasets.find(
                    dataset => dataset.id === material.name
                );

                if (addedDataset) {
                    setDataApiledChart(prevData => {
                        const datasets = [...prevData.datasets, addedDataset];
                        const basuraDatasets = datasets.filter(
                            dataset => dataset.label === 'Basura'
                        );
                        const otherDatasets = datasets
                            .filter(dataset => dataset.label !== 'Basura')
                            .sort((a, b) => a.kind.localeCompare(b.kind));
                        const sortedDatasets = [
                            ...basuraDatasets,
                            ...otherDatasets
                        ];
                        return {
                            ...prevData,
                            datasets: sortedDatasets
                        };
                    });
                    setRemovedDatasets(prevRemovedDatasets =>
                        prevRemovedDatasets.filter(
                            removedDataset =>
                                removedDataset.id !== material.name
                        )
                    );
                    return { ...material, selected: true };
                }
            }
            return material;
        });
        dispatch(setMaterialData(updatedMaterials));
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (labels.length > 0) {
            const startDate = dayjs(
                `${labels[0].slice(0, 4)}-${labels[0].slice(4, 6)}-01`
            );
            const endDate = dayjs(
                `${labels[labels.length - 1].slice(0, 4)}-${labels[
                    labels.length - 1
                ].slice(4, 6)}-01`
            ).endOf('month');
            setDefaultStartDate(startDate);
            setDefaultEndDate(endDate);
        }
    }, [labels]);

    const handleScroll = direction => {
        const container = document.querySelector(
            '.evolution__chart-container__filters__materials__filter__list'
        );

        if (container) {
            const scrollAmount = 200;

            if (direction === 'left') {
                container.scrollLeft -= scrollAmount;
            } else {
                container.scrollLeft += scrollAmount;
            }
        }
    };

    useEffect(() => {
        const allSelected = materialData.every(material => material.selected);
        setSelectAll(allSelected);
    }, [materialData]);

    useEffect(() => {
        const evolutionCard = localStorage.getItem('evolution-card-historic');
        const parsedEvolutionCard = evolutionCard
            ? JSON.parse(evolutionCard)
            : [];
        const parsedEvolutionData = parsedEvolutionCard.data
            ? JSON.parse(parsedEvolutionCard.data)
            : initValues;
        if (parsedEvolutionData) {
            setRecolectionData(parsedEvolutionData);
            const labels = parsedEvolutionData.labels;
            const arrayLabels = FormatLabels(labels);
            setLabelList(labels);
            setLabels(arrayLabels);
            const last12Labels = parsedEvolutionData.labels.slice(-12);
            const updatedDatasets = parsedEvolutionData.datasets.map(
                dataset => ({
                    ...dataset,
                    data: dataset.data.slice(-12)
                })
            );
            const updatedParsedEvolutionData = {
                ...parsedEvolutionData,
                labels: last12Labels,
                datasets: updatedDatasets
            };
            setDataApiledChart(updatedParsedEvolutionData);
            const updatedMaterials = materialData.map(material => {
                const dataset = updatedParsedEvolutionData.datasets.find(
                    objeto => objeto.id === material.name
                );
                if (dataset) {
                    const total = dataset.data
                        .map(Number)
                        .reduce(
                            (a, b) => (isNaN(b) ? a : Number(a) + Number(b)),
                            0
                        );
                    return {
                        ...material,
                        show: total > 0
                    };
                }
                return {
                    ...material,
                    show: false
                };
            });
            dispatch(setMaterialData(updatedMaterials));

            const selectedMaterials = updatedMaterials.filter(
                material => material.selected
            );
            const selectedMaterialIds = selectedMaterials.map(
                material => material.name
            );
            // Filtra dataApiledChart según los IDs de los materiales seleccionados
            if (selectedMaterialIds.length > 0) {
                const filteredDatasets =
                    updatedParsedEvolutionData.datasets.filter(dataset =>
                        selectedMaterialIds.includes(dataset.id)
                    );
                const dataFormatedApiledFiltered = {
                    labels: updatedParsedEvolutionData.labels,
                    datasets: filteredDatasets
                };
                setDataApiledChart(dataFormatedApiledFiltered);
                const removedDatasets =
                    updatedParsedEvolutionData.datasets.filter(
                        dataset => !selectedMaterialIds.includes(dataset.id)
                    );
                setRemovedDatasets(removedDatasets);
            }
        }
    }, []);

    useEffect(() => {
        formatLabelsShow(dataApiledChart.labels);
    }, [dataApiledChart]);

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className='evolution'>
                <Header title={{ name: title }} />
                <div className='evolution__chart-container'>
                    <div className='evolution__chart-container__filters'>
                        <div className='evolution__chart-container__filters__materials'>
                            <div className='evolution__chart-container__filters__materials__title subtitle'>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Checkbox: {
                                                colorPrimary: '#C4C3C5',
                                                colorBorder: '#C4C3C5',
                                                colorText: '#C4C3C5',
                                                colorPrimaryHover: '#C4C3C5'
                                            }
                                        }
                                    }}
                                >
                                    <Checkbox
                                        className='evolution__chart-container__filters__materials__title__checkbox'
                                        checked={selectAll}
                                        onChange={() => unselectAll()}
                                    />
                                    <label htmlFor='selectAll'>
                                        Seleccionar todos
                                    </label>
                                </ConfigProvider>
                            </div>
                            <div className='evolution__chart-container__filters__materials__filter'>
                                <button
                                    className='evolution__chart-container__filters__materials__filter__arrow-btn title'
                                    onClick={() => handleScroll('left')}
                                >
                                    <LeftOutlined
                                        style={{ height: '100%' }}
                                        rev={''}
                                    />
                                </button>
                                <div className='evolution__chart-container__filters__materials__filter__list'>
                                    {materialData
                                        .filter(material => material.show)
                                        .map(material => (
                                            <Tooltip
                                                title={
                                                    <span
                                                        key={material.name}
                                                        className='main_menu__grid-container__tooltip'
                                                    >
                                                        {material.name}
                                                    </span>
                                                }
                                                color='#fff'
                                                key={material.name}
                                            >
                                                <button
                                                    key={material.name}
                                                    onClick={() =>
                                                        ChangeMaterials(
                                                            material
                                                        )
                                                    }
                                                >
                                                    <img
                                                        className={`evolution__chart-container__filters__materials__filter__list__logo${
                                                            !material.selected
                                                                ? '__unselected'
                                                                : ''
                                                        }`}
                                                        src={`/svg-icons/material-icons/${NormalizeName(
                                                            material.name
                                                        )}.svg`}
                                                        alt={material.name}
                                                        key={material.name}
                                                        onError={e => {
                                                            const target =
                                                                e.target as HTMLImageElement;
                                                            target.src =
                                                                '/svg-icons/material-icons/general.svg';
                                                        }}
                                                    ></img>
                                                </button>
                                            </Tooltip>
                                        ))}
                                </div>
                                <button
                                    className='evolution__chart-container__filters__materials__filter__arrow-btn title'
                                    onClick={() => handleScroll('right')}
                                >
                                    <RightOutlined rev={''} />
                                </button>
                            </div>
                        </div>
                        <div className='evolution__chart-container__filters__date-filter'>
                            <div className='evolution__chart-container__filters__date-filter__title subtitle'>
                                {dateRangeTitle}
                            </div>
                            {defaultStartDate && (
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            DatePicker: {
                                                colorLink: '#a2a2a3',
                                                colorLinkActive: '#a2a2a3',
                                                colorPrimary: '#a2a2a3',
                                                colorPrimaryBorder: '#a2a2a3',
                                                colorPrimaryHover: '#a2a2a3',
                                                colorBgContainer: '#f3f2f5',
                                                colorBorder: '#fff',
                                                fontFamily: 'Roboto',
                                                colorIcon: '#a2a2a3',
                                                colorText: '#C4C3C5',
                                                colorTextHeading: '#a2a2a3',
                                                colorTextPlaceholder: '#a2a2a3'
                                            }
                                        }
                                    }}
                                >
                                    <RangePicker
                                        className='evolution__chart-container__filters__date-filter__date-picker'
                                        picker='month'
                                        format={'YYYY-MM'}
                                        onChange={ChangeDate}
                                        defaultValue={[
                                            defaultStartDate,
                                            defaultEndDate
                                        ]}
                                    />
                                </ConfigProvider>
                            )}
                            {LoadingData && <LoaderElement />}
                        </div>
                        <div
                            className='evolution__chart-container__filters__menu'
                            style={{ height: '100%' }}
                        >
                            <div
                                className='evolution__chart-container__filters__menu__title'
                                style={{ height: '100%' }}
                            ></div>
                            <div
                                className='evolution__chart-container__filters__menu__icon'
                                style={{ height: '100%' }}
                            >
                                <Popover
                                    className='evolution__popover'
                                    placement='bottom'
                                    trigger='click'
                                    /* visible={popoverGDVisible}
                                    onVisibleChange={(visible) => setPopoverGDVisible(true)} */
                                    content={
                                        <div className='evolution__popover__container'>
                                            <button
                                                type='button'
                                                onClick={DownloadChart}
                                                className='button button-body--download'
                                            >
                                                {downloadChartButton}
                                            </button>
                                            <button
                                                type='button'
                                                onClick={DownloadData}
                                                className='button button-body--download'
                                            >
                                                {downloadDataButton}
                                            </button>
                                        </div>
                                    }
                                >
                                    <MoreOutlined
                                        style={{ color: '#1f1f1f' }}
                                        rev={''}
                                    />
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className='evolution__chart-container__chart'>
                        <Stacked1.StackedBarChartComponent
                            refProp={ref}
                            chartData={dataApiledChart}
                            options={{
                                ...Stacked1.Config,
                                scales: {
                                    ...Stacked1.Config.scales,
                                    x: {
                                        ...Stacked1.Config.scales.x,
                                        ticks: {
                                            ...Stacked1.Config.scales.x.ticks,
                                            callback: (
                                                value,
                                                index,
                                                values
                                            ) => {
                                                return formattedLabels[index]; // Mostrar labels formateados si hay propiedades seleccionadas
                                            }
                                        }
                                    }
                                }
                                /* animation: {
                                    delay: context => {
                                        let delay = 0;
                                        if (context.type === 'data') {
                                            delay =
                                                context.dataIndex * 150 +
                                                context.datasetIndex * 50;
                                        }
                                        return delay;
                                    }
                                } */
                            }}
                            dateChangeManager={() => {}}
                        />
                    </div>
                    <button
                        className='evolution__chart-container__btn'
                        onClick={() => navigate('/panel/registers')}
                    >
                        {viewDetailsText}
                    </button>
                </div>
                <div className='evolution__others'>
                    <RecolectionCard
                        data={recolectionData}
                        dateFilterRange={
                            dateFilterRange.length < 2
                                ? [
                                      defaultStartDate?.format('YYYYMM'),
                                      defaultEndDate?.format('YYYYMM')
                                  ]
                                : dateFilterRange
                        }
                    />
                    <BZeroIndexCard index={bzeroindex} />
                </div>
            </div>
            {expandOpen && (
                <div className='expandedChart'>
                    <div className='expandedChart__container'>
                        <div
                            className='evolution__chart-container__filters '
                            style={{ flexDirection: 'row', height: '10%' }}
                        >
                            {/* {!isMobile && ( */}
                            <div
                                className='evolution__chart-container__filters__materials'
                                style={{ height: '100%' }}
                            >
                                <div className='evolution__chart-container__filters__materials__filter'>
                                    <button
                                        className='evolution__chart-container__filters__materials__filter__arrow-btn title'
                                        onClick={() => handleScroll('left')}
                                    >
                                        <LeftOutlined
                                            style={{ height: '100%' }}
                                            rev={''}
                                        />
                                    </button>
                                    <div className='evolution__chart-container__filters__materials__filter__list'>
                                        {materialData.map(material => (
                                            <Tooltip
                                                title={
                                                    <span
                                                        key={material.name}
                                                        className='main_menu__grid-container__tooltip'
                                                    >
                                                        {material.name}
                                                    </span>
                                                }
                                                color='#fff'
                                                key={material.name}
                                            >
                                                <button
                                                    key={material.name}
                                                    onClick={() =>
                                                        ChangeMaterials(
                                                            material
                                                        )
                                                    }
                                                >
                                                    <img
                                                        className={`evolution__chart-container__filters__materials__filter__logo${
                                                            !material.selected
                                                                ? '__unselected'
                                                                : ''
                                                        }`}
                                                        src={`/svg-icons/material-icons/${NormalizeName(
                                                            material.name
                                                        )}.svg`}
                                                        alt={material.name}
                                                        key={material.name}
                                                    />
                                                </button>
                                            </Tooltip>
                                        ))}
                                    </div>
                                    <button
                                        className='evolution__chart-container__filters__materials__filter__arrow-btn title'
                                        onClick={() => handleScroll('right')}
                                    >
                                        <RightOutlined rev={''} />
                                    </button>
                                </div>
                            </div>
                            <div
                                className='evolution__chart-container__filters__date-filter'
                                style={{ height: '100%' }}
                            >
                                {defaultStartDate && (
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                DatePicker: {
                                                    colorLink: '#a2a2a3',
                                                    colorLinkActive: '#a2a2a3',
                                                    colorPrimary: '#a2a2a3',
                                                    colorPrimaryBorder:
                                                        '#a2a2a3',
                                                    colorPrimaryHover:
                                                        '#a2a2a3',
                                                    colorBgContainer: '#f3f2f5',
                                                    colorBorder: '#fff',
                                                    fontFamily: 'Roboto',
                                                    colorIcon: '#a2a2a3',
                                                    colorText: '#C4C3C5',
                                                    colorTextHeading: '#a2a2a3',
                                                    colorTextPlaceholder:
                                                        '#a2a2a3'
                                                }
                                            }
                                        }}
                                    >
                                        <RangePicker
                                            className='evolution__chart-container__filters__date-filter__date-picker'
                                            style={{ height: '100%' }}
                                            picker='month'
                                            format={'YYYY-MM'}
                                            onChange={ChangeDate}
                                            defaultValue={[
                                                defaultStartDate,
                                                defaultEndDate
                                            ]}
                                        />
                                    </ConfigProvider>
                                )}
                                {LoadingData && <LoaderElement />}
                            </div>
                            <div
                                className='evolution__chart-container__filters__menu'
                                style={{ height: '100%' }}
                            ></div>
                        </div>
                        <div className='expandedChart__container__chart'>
                            <Stacked1.StackedBarChartComponent
                                refProp={ref}
                                chartData={dataApiledChart}
                                options={{
                                    ...Stacked1.Config,
                                    scales: {
                                        ...Stacked1.Config.scales,
                                        x: {
                                            ...Stacked1.Config.scales.x,
                                            ticks: {
                                                ...Stacked1.Config.scales.x
                                                    .ticks,
                                                callback: (
                                                    value,
                                                    index,
                                                    values
                                                ) => {
                                                    return formattedLabels[
                                                        index
                                                    ]; // Mostrar labels formateados si hay propiedades seleccionadas
                                                }
                                            }
                                        }
                                    }
                                }}
                                dateChangeManager={() => {}}
                            />
                        </div>
                        <div
                            className='evolution__chart-container__btns'
                            style={{ marginRight: 'auto' }}
                        >
                            <button
                                className='evolution__chart-container__btns__item'
                                onClick={() => setExpandOpen(!expandOpen)}
                            >
                                <img
                                    className='evolution__chart-container__btns__item__icon'
                                    src={`/svg-icons/${
                                        expandOpen
                                            ? 'ContractIcon.svg'
                                            : 'ExpandIcon.svg'
                                    }`}
                                />
                            </button>
                            <button
                                className='evolution__chart-container__btns__item'
                                onClick={DownloadChart}
                            >
                                <img
                                    className='evolution__chart-container__btns__item__icon'
                                    src='/svg-icons/DownloadIcon.svg'
                                />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Evolution;
